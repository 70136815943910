.button {
    border-radius: 10rem;
    padding: 0.6rem;
    letter-spacing: 2px;
    font-size: 0.9rem;
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
    transition: all ease 0.175s;
    outline: none;
    box-sizing: border-box;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button div {
    align-self: center;
    line-height: 1.1rem;
    margin: auto;
    align-content: center;
    justify-content: center;
    text-align: center;
}

.button:hover {
    backface-visibility: hidden;
    cursor: pointer;
    transform: scale(1.05);
    filter: brightness(110%);
}

.button-green {
    background: var(--spotify_green);
    color: var(--spotify_white);
    border: solid 1px var(--spotify_green);
}

.button-white {
    background: var(--spotify_white);
    border: solid 1px var(--spotify_white);
}

.button-transparent {
    background-color: var(--spotify_black);
    color: var(--spotify_white);
    border: solid 1px #888;
}

.button-transparent:hover {
    border: solid 1px var(--spotify_white);
}
