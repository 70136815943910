.text-input {
    background: var(--spotify_gray);
    padding: 0.5rem 1rem 0.5rem 15%;
    border: none;
    outline: none;
    color: var(--spotify_white);
    border-radius: 4px;
    position: absolute;
    z-index: 1;
    left: 10%;
    width: 80%;
    box-sizing: border-box;
    opacity: 0;
    transition: ease 0.2s;
}

.text-input.visible {
    width: 90%;
    opacity: 1;
}
