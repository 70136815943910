.album-tilt {
    height: 50vw;
    width: 50vw;
    max-height: 65vh;
    max-width: 65vh;
}

.album-tilt img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 860px) {
    .album-tilt {
        width: 80vw;
        height: 80vw;
    }
}
