.header {
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-start;
}

.header-name {
    position: absolute;
    left: 2rem;
}

.header-name:hover {
    cursor: pointer;
}

.header-code {
    color: var(--spotify_white);
    font-weight: 300;
    text-align: center;
    flex: 1;
}

.header-person-icon {
    color: var(--spotify_white);
    display: flex;
    font-size: 1.75rem;
    position: absolute;
    right: 2rem;
    top: 0.85rem;
    justify-content: center;
    align-items: center;
    line-height: 2rem;
    font-weight: 300;
}

.header-person-icon svg {
    margin: 0 0 0 0.5rem;
    fill: var(--spotify_white);
    height: 1.75rem;
    padding: 0;
    position: relative;
    top: -1px;
}

@media screen and (max-width: 860px) {
    .header-code {
        max-width: 50vw;
        margin: 0.5rem auto;
        align-self: center;
        font-size: 1rem;
    }
}
