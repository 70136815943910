.search-input {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 0.75rem;
    margin: 0.25rem 0;
    position: sticky;
    top: 4rem;

    input {
        width: 100%;
        border-radius: 5px;
        border: none;
        outline: none;
        color: var(--spotify_text_gray);
        padding: 10px;
        transition: ease 0.15s;
        background-color: var(--spotify_gray);
        caret-color: var(--spotify_green);

        &:focus {
            color: var(--spotify_white);
            background-color: rgba(200, 200, 200, 0.2);
            & + button {
                color: var(--spotify_white);
            }
        }
    }

    button {
        position: absolute;
        right: 1.3rem;
        background-color: transparent;
        color: var(--spotify_text_gray);
        border: none;
        outline: none;
        padding: 0;
        top: 0;
        bottom: 0;
        margin: auto 0;

        &:hover {
            cursor: pointer;
        }
    }

    @media screen and (max-width: 860px) {
        box-sizing: border-box;
    }
}
