.host-content {
    display: grid;
    grid-template-columns: 10fr 8fr;
    grid-template-rows: 1fr;
    width: 95vw;
    height: 88vh;
    padding: 12vh 3.5vh 0 1.5vh;

    @media screen and (max-width: 860px) {
        display: flex;
        flex-direction: column;
        height: auto;
        width: 100vw;
        padding: 12vh 0 20vh 0;
    }
}
