.list-container {
    width: 100%;
    max-height: calc(100vh - 8rem);
    overflow: scroll;
    position: relative;
    scroll-behavior: smooth;

    &::after {
        content: '';
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 5rem;
        background-image: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    }
}

.track-list {
    list-style: none;
    padding: 0 0 4rem 0;
    margin: 0 0 0 0;
    width: 100%;

    li {
        margin: 0;
        padding: 0.8rem 0.5rem;
        display: grid;
        grid-template-columns: 6vh 6fr 3fr;
        grid-template-rows: 1fr;
        justify-content: space-around;

        &:not(:first-of-type) {
            border-top: solid 1px var(--spotify_gray);
        }

        &:hover {
            background-color: var(--spotify_gray);
        }

        > div:nth-of-type(2) {
            display: flex;
            align-items: center;
            justify-content: space-between;

            > div {
                margin-left: 0.5rem;
                font-size: 0.85rem;
                color: var(--spotify_text_gray);
            }
        }

        @media screen and (max-width: 860px) {
            margin: 0 0.75rem;
        }
    }

    img {
        height: 6vh;
        width: 6vh;
        max-width: 6rem;
        max-height: 6rem;
    }

    .track-names {
        padding-left: 0.7rem;
        display: flex;
        flex-direction: column;
        justify-content: center;

        h3 {
            text-align: left;
            margin: 0;
            font-size: 0.8rem;
        }

        div:last-of-type {
            font-size: 0.6rem;
            color: var(--spotify_text_gray);
        }

        h3,
        div:last-of-type {
            max-width: 45vw;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    div:last-of-type {
        color: var(--spotify_white);
    }

    button {
        color: var(--spotify_white);
        font-size: 1.5rem;
        background: none;
        border: none;
        margin: 0 0.25rem 0 auto;
        display: flex;
        align-items: center;
        padding: 0;
        outline: none;

        &:hover {
            cursor: pointer;
        }

        svg {
            height: 1.5rem;
            fill: var(--spotify_white);
            transition: ease 0.25s;

            &:active {
                // fill: var(--spotify_green);
                transform: scale(1.2);
            }
        }
    }
}
