.start {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.start-buttons {
    display: flex;
    flex-direction: column;
}

.start-buttons > :first-child {
    margin-bottom: 3rem;
}

.join-button {
    display: flex;
    position: relative;
    align-items: center;

    button {
        width: 100%;
        z-index: 2;
        position: absolute;
    }

    &.show button {
        width: 19%;
        letter-spacing: 0;

        &.loading {
            // animation: rotation 0.75s infinite;
        }
    }
}

.start-error {
    min-width: 240px;
    position: absolute;
    align-self: center;
    text-align: center;
    bottom: 0;
    transform: translateY(200%);
    padding: 1rem;
    // background-color: var(--error_red);
    color: var(--error_red);
    border-radius: 3px;
    box-sizing: content-box;
}

@media screen and (max-width: 780px) {
    .start-buttons {
        margin-bottom: 4rem;
    }

    .start-error {
        transform: translateY(100%);
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
