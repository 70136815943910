.player-loading-overlay {
    top: 0;
    left: 0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: grayscale(0.5) blur(1.2px);
    transition: opacity ease 250ms;
}

.player-loading-overlay.disabled {
    opacity: 0;
}

.player {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    h3,
    p {
        margin-bottom: 0;
    }

    p {
        margin-top: 0.5rem;
    }

    @media screen and (max-width: 860px) {
        margin-bottom: 1rem;
    }
}

.player-controller {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 60vh;
    max-width: 100vw;
    margin-top: 0.25rem;
    padding: 1rem;
    box-sizing: border-box;

    @media screen and (max-width: 860px) {
        background-color: var(--spotify_gray);
        position: fixed;
        bottom: 0;
        width: 100vw;
        z-index: 999;
    }
}

.player-controller-controlls {
    display: flex;
    width: 80%;
    margin: 0 auto;
    justify-content: space-evenly;
}

.player-controll-connect {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
        position: absolute;
        background-color: var(--spotify_gray);
        border-radius: 5px;
        transform: translateY(-100%);
        box-shadow: 0 4px 20px 4px rgba(0, 0, 0, 0.5);
        top: -1rem;
        min-width: 220px;
        display: block;

        &::before {
            bottom: -20px;
            left: calc(50% - 10px);
            border: 10px solid transparent;
            border-top-color: #282828;
            position: absolute;
            content: '';
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                overflow: hidden;

                &:first-of-type {
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                }

                &:last-of-type {
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
            }

            .loading {
                display: flex;
                justify-content: center;
                padding: 1rem;
            }
        }

        button {
            min-width: 100%;
            outline: none;
            background: none;
            border: none;
            padding: 1rem 2rem 1rem 1rem;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            text-align: left;
            color: var(--spotify_white);

            &.device-active {
                color: var(--spotify_green);

                svg {
                    stroke: var(--spotify_green);

                    .icon-fill {
                        fill: var(--spotify_green);
                    }
                }
            }

            &.device-restricted {
                color: var(--spotify_text_gray);

                svg {
                    stroke: var(--spotify_text_gray);

                    .icon-fill {
                        fill: var(--spotify_text_gray);
                    }
                }
            }

            &:hover {
                background-color: var(--spotify_medium_gray);
                cursor: pointer;
            }

            svg {
                fill: none;
                height: 2rem;
                width: 2rem;
                stroke: var(--spotify_white);

                .icon-fill {
                    fill: var(--spotify_white);
                }
            }

            p {
                letter-spacing: 1px;
                margin: 0 0 0 1rem;
                font-size: 0.9rem;
                font-weight: 700;
                white-space: nowrap;
                max-width: 280px;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
}

.player-controll-button {
    outline: none;
    background: none;
    color: var(--spotify_white);
    border: none;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    &:hover {
        cursor: pointer;
        filter: brightness(140%);
    }

    svg {
        stroke: var(--spotify_text_gray);

        &:not(.icon-connect) {
            width: 0.9rem;
        }

        &.icon-connect {
            fill: none;
            width: 1rem;
        }
    }

    .icon-fill {
        fill: var(--spotify_text_gray);
    }

    &.circle {
        border: solid 1px var(--spotify_text_gray);
        padding: 12px;

        &:hover {
            transform: scale(1.04);
        }
    }

    &.play svg {
        position: relative;
        left: 1px;
    }
}

.progress-bar-container {
    position: relative;
    width: 90%;
    align-self: center;
    display: flex;
    justify-content: center;
    margin: 1rem 0 0.5rem 0;

    > div:not(:nth-of-type(2)) {
        position: absolute;
        font-size: 0.5rem;
        top: -6px;
    }

    > div:first-of-type {
        left: -18px;
        text-align: left;
    }

    > div:last-of-type {
        right: -18px;
        text-align: right;
    }
}

.progress-bar {
    position: relative;
    width: 90%;
    height: 5px;
    background-color: var(--spotify_medium_gray);
    border-radius: 3px;

    &::before {
        content: '';
        position: absolute;
        top: -4px;
        height: 14px;
        width: 100%;
    }

    div {
        background-color: var(--spotify_text_gray);
        position: absolute;
        transform-origin: left;
        // width: 100%;
        height: 5px;
        top: 0;
        left: 0;
        content: '';
        border-radius: 3px;
    }

    &:hover {
        cursor: pointer;
        div {
            background-color: var(--spotify_green);
            &::after {
                position: absolute;
                right: -6px;
                top: -3px;
                content: '';
                border-radius: 50%;
                height: 12px;
                width: 12px;
                padding: 0;
                margin: 0;
                background-color: var(--spotify_text_gray);
            }
        }
    }
}
