.App {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    color: #fff;
    overflow: hidden;
}

.green {
    color: var(--spotify_green);
}

.white {
    color: #fff;
}

.gray {
    color: var(--spotify_gray);
}

.xs {
    font-size: 0.5rem;
}

.s {
    font-size: 1rem;
}

.m {
    font-size: 2rem;
}

.l {
    font-size: 3rem;
}

.xl {
    font-size: 4rem;
}
